// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from 'src/store'
import user from 'src/service/user'
import 'src/@eyevision/basecss/index.css'
// import 'colors.css/css/colors.css' // 颜色库
// import 'basscss/css/basscss.css' // css辅助库
import 'normalize.css'
import 'assets/icon/iconfont.css'
import 'src/boot/importMintui'
import 'src/boot/importComponent'
import 'assets/style/color.css'
import 'assets/style/base.css'
import 'assets/style/table.css'
import axiosHandle from 'src/boot/axiosHandle'
import axios from 'axios'
import axiosBigNumber from 'src/@eyevision/axios-bignum-plugin'
import axiosErrorHander from 'src/@eyevision/axios-error'
import {MessageBox, Indicator} from 'mint-ui'
import * as api from 'src/api'
import config from 'src/config'
import 'assets/style/reboot.css'
import constant from 'src/const'
import 'src/filters'
import 'assets/icon/iconfont-color.js'
import 'assets/icon/iconfont.js'
import eventBus, {FORCE_UPDATE_APP} from 'src/event'
import website from 'src/website'
import {Image, Divider, Dialog, Button, Cascader} from 'element-ui'

Vue.use(Image)
Vue.use(Divider)
Vue.use(Dialog)
Vue.use(Button)
Vue.use(Cascader)
if (!window.Promise) {
  window.Promise = require('promise')
}
website.init()
Vue.prototype.$const = constant
axiosBigNumber(axios)
axiosHandle(axios, config.host)
axiosErrorHander(axios, (errorDetail) => {
  const gotoLogin = () => {
    user.clearToken()
    eventBus.$emit(FORCE_UPDATE_APP)
  }
  if (errorDetail.status === 403) {
    if (process.env.VUE_APP_ENV !== 'development') {
      gotoLogin()
    }
  } else if (errorDetail.status === 401) {
    gotoLogin()
  } else if (errorDetail.status === 410) {
    gotoLogin()
  } else {
    MessageBox.alert(errorDetail.message || errorDetail.tip)
  }
})
if (process.env.NODE_ENV === 'development') {
  axios.interceptors.request.use((config) => {
    // console.log(config)
    // config.headers.common['Eyevision'] = 'aws'
    return config
  }, function (error) {
    return Promise.reject(error)
  })
}
Vue.prototype.$loading = (text = '数据提交中,请稍后') => {
  Indicator.open({
    text
  })
}
Vue.prototype.$closeLoading = () => {
  Indicator.close()
}
// eslint-disable-next-line
Array.prototype.toBinInt = function () {
  let binary = 0
  for (let item of this) {
    binary += item
  }
  return binary
}
// eslint-disable-next-line
Number.prototype.toBinArray = function () {
  const value = parseInt(this).toString(2)
  let result = []
  for (let i = 0; i < value.length; i++) {
    const v = parseInt(value[i])
    if (v === 1) {
      result.push(Math.pow(2, value.length - i - 1))
    }
  }
  return result
}
Vue.config.productionTip = false
Vue.use(require('vue-progressbar'))
Vue.use(require('vue-moment'))
// api.setDomain(config.host)
Vue.prototype.$api = api

const initApp = () => {
  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>'
  })
}

const initErrorPage = () => {
  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    components: {App: () => import('pages/NetworkError/NetworkError')},
    template: '<App/>'
  })
}

const boot = async () => {
  try {
    await user.getSystemInfo()
    await user.initTokenByTicket()
    initApp()
  } catch (error) {
    console.error(error)
    initErrorPage()
  }
}

boot()
