<template lang="pug">
  .App.full-height
    template(v-if="!needAuth()")
      keep-alive(:include="keepAliveListStr")
        router-view(:style="[routeViewMarginBottom]")
      NavBar(v-show="showNav")
    Login(v-else)
    vue-progress-bar
</template>

<script>
import NavBar from 'views/NavBar/NavBar'
import eventBus, {HIDE_NAV, FORCE_UPDATE_APP} from 'src/event'
import user from 'src/service/user'
export default {
  name: 'App',
  data () {
    return {
      showNav: false,
      keepAliveList: []
    }
  },
  created () {
    this.initProgress()
    this.routerAfterHandle()
  },
  computed: {
    keepAliveListStr () {
      return this.keepAliveList.join(',')
    },
    routeViewMarginBottom () {
      if (this.showNav) {
        return {
          marginBottom: '140px',
          paddingBottom: '50px'
        }
      } else {
        return null
      }
    }
  },
  methods: {
    needAuth () {
      if (user.isGuest()) {
        if (this.$route.meta.requiresAuth) {
          return true
        }
        return false
      } else {
        return false
      }
    },
    routerAfterHandle () {
      this.$router.afterEach((to, from) => {
        window.document.body.scrollTop = 0
        this.backgroundHandle(to)
        this.navBarVisibleHandle(to)
      })
    },
    navBarVisibleHandle (to) {
      if (!to.meta || to.meta.hideNav) {
        this.showNav = false
      } else {
        this.showNav = true
      }
    },
    backgroundHandle (to) {
      if (to.meta && to.meta.background) {
        document.querySelector('html').style.background = to.meta.background
      } else {
        document.querySelector('html').style.background = '#F2F2F2'
      }
    },
    initProgress () {
      this.$Progress.start()
      this.$router.beforeEach((to, from, next) => {
        this.$Progress.start()
        next()
      })
      this.$router.afterEach((to, from) => {
        this.$Progress.finish()
      })
      this.$router.onError((e) => {
        this.$Progress.fail()
        this.$messageBox.alert('页面加载错误,请检查网络连接或尝试刷新重试')
      })
    }
  },
  mounted () {
    this.$Progress.finish()
    eventBus.$on(HIDE_NAV, (val) => {
      this.showNav = val
    })
    eventBus.$on(FORCE_UPDATE_APP, (val) => {
      this.$forceUpdate()
    })
  },
  components: {
    NavBar,
    Login: () => import('pages/Login/Login.vue')
  }
}
</script>

<style scoped>
</style>
